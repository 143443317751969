<template>
    <div class="home container">
        <div class="home-box flex">
            <div class="home-slider home-left">
                <div class="home-item">
                    <div class="home-title">商城收益分析</div>
                    <div class="home-border">
                        <ul class="home-tab list-style flex">
                            <li>
                                <div class="home-tab-con">
                                    <i class="line"></i>
                                    <strong>{{info.shop_day}}</strong>
                                    <p>日收益</p>
                                </div>
                            </li>
                            <li>
                                <div class="home-tab-con">
                                    <i class="line"></i>
                                    <strong>{{info.shop_month}}</strong>
                                    <p>月收益</p>
                                </div>
                            </li>
                            <li>
                                <div class="home-tab-con">
                                    <i class="line"></i>
                                    <strong>{{info.shop_year}}</strong>
                                    <p>年收益</p>
                                </div>
                            </li>
                        </ul>
                        <BarChart className="leftTopChart" :chartData="leftTopChartData" height="3.4rem"></BarChart>
                    </div>
                </div>
                <div class="home-item">
                    <div class="home-title">二手成交分析</div>
                    <div class="home-border">
                        <div ref="leftCenterChart" class="left-center-bar"></div>
                    </div>
                </div>
                <div class="home-item">
                    <div class="home-title">游艇房车成交分析</div>
                    <div class="home-border">
                        <div ref="leftBottomChart" class="left-bottom-bar"></div>
                    </div>
                </div>
            </div>
            <div class="home-slider home-center">
                <div class="home-item">
                    <ul class="home-top-tab list-style flex">
                        <!-- <li @click="changeType(index)" v-for="(item, index) in type" :key="index" :class="{ 'active': activeType === index }"><strong>{{item.number}}</strong><span>{{item.text}}</span></li> -->
                        <li><strong>{{info.app_down_num}}</strong><span>APP下载量</span></li>
                        <li><strong>{{info.store_num}}</strong><span>商家总数量</span></li>
                        <li><strong>{{info.member_num}}</strong><span>会员总数量</span></li>
                    </ul>
                    <div class="home-top">
                        <ul ref="homeList" class="home-list list-style flex flex-wrap justify-center">
                            <li v-for="(item, index) in list" :key="index">
                                <div class="home-list-item">
                                    <strong>{{item.number}}</strong>
                                    <h3>{{item.text}}</h3>
                                    <p>{{item.unit}}</p>
                                    <div class="home-list-icon">
                                        <img :src="item.img">
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="home-item">
                    <div class="home-title home-title-long">APP下载趋势</div>
                    <div class="home-border">
                        <ul class="home-tab list-style">
                            <li>
                                <div class="home-tab-con">
                                    <i class="line"></i>
                                    <img src="~@/assets/icon/center-icon.webp" alt="">
                                    <strong>{{info.app_day}}%</strong>
                                    <p>日增长率</p>
                                </div>
                            </li>
                            <li>
                                <div class="home-tab-con">
                                    <i class="line"></i>
                                    <img src="~@/assets/icon/center-icon.webp" alt="">
                                    <strong>{{info.app_month}}%</strong>
                                    <p>月增长率</p>
                                </div>
                            </li>
                            <li>
                                <div class="home-tab-con">
                                    <i class="line"></i>
                                    <img src="~@/assets/icon/center-icon.webp" alt="">
                                    <strong>{{info.app_year}}%</strong>
                                    <p>年增长率</p>
                                </div>
                            </li>
                        </ul>
                        <div ref="centerBottomChart" class="center-bottom-bar"></div>
                    </div>
                </div>
            </div>
            <div class="home-slider home-right">
                <div class="home-item">
                    <div class="home-title">商品类型分析</div>
                    <div class="home-border">
                        <div ref="rightTopChart" class="right-top-bar"></div>
                    </div>
                </div>
                <div class="home-item">
                    <div class="home-title">渔场上线分析</div>
                    <div class="home-border">
                        <BarChart className="rightCenterChart" :chartData="rightCenterData" height="1.7rem"></BarChart>
                    </div>
                </div>
                <div class="home-item">
                    <div class="home-title">赛事上线分析</div>
                    <div class="home-border">
                        <BarChart className="rightbottomChart" :chartData="rightbottomData" height="1.7rem"></BarChart>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright color-f text-center">
            <p>备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">黔ICP备2023012124号-1</a></p>
        </div>
        <div class="mask" v-if="showMask">
            <van-loading type="spinner" color="#1989fa" />
        </div>
    </div>
</template>
<script>
import daping from '@/api/daping'
import BarChart from '../components/BarChart'
export default {
    name: 'Home',
    components:{
        BarChart
    },
    data() {
        return {
            info:'',
            type:[{
                number:432834,
                text:'APP下载量'
            },{
                number:834,
                text:'商家总数量'
            },{
                number:212834,
                text:'会员总数量'
            }],
            activeType: 0,
            list:[{
                number:0,
                text:'商城商品数',
                unit:'（件）',
                img: require('@/assets/icon/icon1.webp')
            },{
                number:0,
                text:'成交笔数',
                unit:'（件）',
                img: require('@/assets/icon/icon2.webp')
            },{
                number:0,
                text:'成交金额',
                unit:'（元）',
                img: require('@/assets/icon/icon3.webp')
            },{
                number:0,
                text:'退单笔数',
                unit:'（笔）',
                img: require('@/assets/icon/icon4.webp')
            },{
                number:0,
                text:'退单金额',
                unit:'（元）',
                img: require('@/assets/icon/icon5.webp')
            },{
                number:0,
                text:'商城下架数',
                unit:'（件）',
                img: require('@/assets/icon/icon1.webp')
            },{
                number:0,
                text:'物流单数',
                unit:'（单）',
                img: require('@/assets/icon/icon2.webp')
            },{
                number:0,
                text:'二手总成交',
                unit:'（单）',
                img: require('@/assets/icon/icon3.webp')
            },{
                number:0,
                text:'二手总成交金额',
                unit:'（元）',
                img: require('@/assets/icon/icon4.webp')
            },{
                number:0,
                text:'游艇房车成交数',
                unit:'（单）',
                img: require('@/assets/icon/icon5.webp')
            },{
                number:0,
                text:'渔场上线',
                unit:'（家）',
                img: require('@/assets/icon/icon6.webp')
            },{
                number:0,
                text:'赛事上线',
                unit:'（场）',
                img: require('@/assets/icon/icon7.webp')
            },{
                number:0,
                text:'实体渔具',
                unit:'商超加盟',
                img: require('@/assets/icon/icon8.webp')
            },{
                number:0,
                text:'上线动态',
                unit:'（条）',
                img: require('@/assets/icon/icon9.webp')
            },{
                number:0,
                text:'政策法规',
                unit:'（条）',
                img: require('@/assets/icon/icon10.webp')
            },{
                number:0,
                text:'未使用积分',
                unit:'',
                img: require('@/assets/icon/icon11.webp')
            },{
                number:0,
                text:'评论总数',
                unit:'（条）',
                img: require('@/assets/icon/icon12.webp')
            }],
            leftTopChartData:{
                color: '#E4CD32',
                dataX: [],
                series: [
                    {
                        name: '商城收益分析',
                        type: 'bar',
                        data: [],
                        itemStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 1, color: 'rgba(228, 205, 50, 0.75)'
                                }, {
                                    offset: 0, color: '#E4CD32'
                                }],
                                global: false // 缺省为 false
                            }
                        },
                        barMaxWidth: 12
                    }
                ]
            },
            rightCenterData: {
                color: '#FC8452',
                dataX: [],
                series: [
                    {
                        name: '渔场上线分析',
                        type: 'bar',
                        data: [],
                        itemStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 1, color: 'rgba(252, 132, 82, 0.60)'
                                }, {
                                    offset: 0, color: '#FC8452'
                                }],
                                global: false // 缺省为 false
                            }
                        },
                        barMaxWidth: 12
                    }
                ]
            },
            rightbottomData: {
                color: '#06BDAB',
                dataX: [],
                series: [
                    {
                        name: '赛事上线分析',
                        type: 'bar',
                        data: [],
                        itemStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 1, color: 'rgba(6, 189, 171, 0.60)'
                                }, {
                                    offset: 0, color: '#06BDAB'
                                }],
                                global: false // 缺省为 false
                            }
                        },
                        barMaxWidth: 12
                    }
                ]
            },
            showMask: true
        }
    },

    computed: {

    },

    created() {

    },
    mounted () {
        this.$nextTick(()=>{
            this.appendDiv();
            this.getInfo();
        })
    },
    methods: {
        changeType(index){
            this.activeType = index
        },
        appendDiv(){
            const ulElement = this.$refs.homeList;
            const liElements = ulElement.querySelectorAll('li');

            for (let i = 0; i < Math.min(10, liElements.length); i++) {
                if ((i + 1) % 5 === 0) {
                    const divElement = document.createElement('div');
                    divElement.classList.add('home-line');
                    ulElement.insertBefore(divElement, liElements[i].nextSibling);
                }
            }
            for (let i = 10; i < liElements.length; i++) {
                if ((i - 10 + 1) % 7 === 0) {
                    const divElement = document.createElement('div');
                    divElement.classList.add('home-line');
                    ulElement.insertBefore(divElement, liElements[i].nextSibling);
                }
            }
        },
        getInfo(){
            daping.info().then((res) => {
                if(res.code == '200'){
                    this.showMask = false;
                    this.info = res.result;
                    this.list[0].number = res.result.goods_num; // 商品数量
                    this.list[1].number = res.result.deal_num; // 成交笔数
                    this.list[2].number = res.result.deal_price; // 成交金额
                    this.list[3].number = res.result.chargeback_num; // 退单笔数
                    this.list[4].number = res.result.chargeback_price; // 退单金额
                    this.list[5].number = res.result.goods_nums; // 商品下架数量
                    this.list[6].number = res.result.wuliu_num; // 物流数量
                    this.list[7].number = res.result.oldorder_num; // 二手订单数量
                    this.list[8].number = res.result.oldorder_price; // 二手成交金额
                    this.list[9].number = res.result.yacht_num; // 游艇房车成交数
                    this.list[10].number = res.result.fish_num; // 渔场上线
                    this.list[11].number = res.result.game_num; // 赛事上线
                    this.list[12].number = res.result.fishing_num; // 实体渔具
                    this.list[13].number = res.result.trends_num; // 上线动态
                    this.list[14].number = res.result.policy_num; // 政策法规
                    this.list[15].number = res.result.integral_num; // 未使用积分
                    this.list[16].number = res.result.comment_num; // 评论总数
                    // 商城收益分析
                    this.leftTopChartData.dataX = res.result.shop_statistics.rydate;
                    this.leftTopChartData.series[0].data = res.result.shop_statistics.order_amount;
                    this.leftTopChartData.series[0].name = '商城收益分析'
                    // 二手成交分析
                    this.leftCenterChart(res.result.old_statistics);
                    //游艇房车成交分析
                    this.leftBottomChart(res.result.yacht_statistics);
                    // APP下载趋势
                    this.centerBottomChart(res.result.app_statistics);
                    // 商品类型分析
                    this.rightTopChart(res.result.goods_statistics)
                    // 渔场上线分析
                    this.rightCenterData.dataX = res.result.fish_statistics.rydate;
                    this.rightCenterData.series[0].data = res.result.fish_statistics.order_amount;
                    this.rightCenterData.series[0].name = '渔场上线分析'
                    // 赛事上线分析                    
                    this.rightbottomData.dataX = res.result.game_statistics.rydate;
                    this.rightbottomData.series[0].data = res.result.game_statistics.order_amount;
                    this.rightbottomData.series[0].name = '赛事上线分析'

                }
            })
        },
        leftCenterChart(data){
            var leftCenterChart = this.$echarts.init(this.$refs.leftCenterChart);
            let leftCenterOption = {
                color: [
                    '#80AAFF',
                ],
                grid: {
                    left: 0,
                    top: 15,
                    bottom: 0,
                    right: 0,
                    containLabel: true
                },
                
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                axisLabel: {
                    color: "rgba(255, 255, 255, .4)",
                    fontSize: 10
                },
                xAxis: {
                    axisTick:{
                        alignWithLabel: true
                    },
                    axisLabel:{
                        interval:'auto',
                    },
                    boundaryGap: false,
                    data: data.rydate
                },
                yAxis: {
                    axisLine:{
                        show: false
                    },
                    axisTick:{
                        show: false,
                    },
                    splitLine:{
                        show: true,
                        lineStyle:{
                            color: 'rgba(255,255,255,0.2)',
                            type:'dashed'
                        }
                    },
                    type: "value",
                    // splitNumber: 10,
                    interval: 100,
                    data: [0, 100, 200, 300, 400, 500, 600]
                },
                series: [
                    {
                        name: '二手成交',
                        type: 'line',
                        smooth: true,
                        lineStyle: {
                            color: '#80AAFF'
                        },
                        showSymbol: false,
                        data: data.order_amount,
                        areaStyle: {
                            opacity: 0.8,
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 1, color: 'rgba(33, 102, 217, .1)'
                                }, {
                                    offset: 0, color: '#2166D9'
                                }],
                                global: false // 缺省为 false
                            }
                        }
                    }
                ]
            };
            leftCenterChart.setOption(leftCenterOption);
            window.addEventListener("resize",function(){
                leftCenterChart.resize();
            });
        },
        leftBottomChart(data){
            var leftBottomChart = this.$echarts.init(this.$refs.leftBottomChart);
            let leftBottomOption = {
                color: [
                    '#E4CD32',
                ],
                grid: {
                    left: 0,
                    top: 35,
                    bottom: 0,
                    right: 0,
                    containLabel: true
                },
                legend: {
                    left: -5,
                    itemWidth: 22,
                    itemHeight: 12,
                    textStyle:{
                        color: '#FFFFFF',
                        fontSize: 12
                    },
                    data: ['游艇', '房车']
                },
                tooltip: {},
                axisLabel: {
                    color: "rgba(255, 255, 255, .4)",
                    fontSize: 10
                },
                xAxis: {
                    axisTick:{
                        alignWithLabel: true
                    },
                    axisLabel:{
                        interval:'auto',
                    },
                    boundaryGap: false,
                    data: data.rydate
                },
                yAxis: {
                    axisLine:{
                        show: false
                    },
                    axisTick:{
                        show: false,
                    },
                    axisLabel:{
                        margin: 20
                    },
                    splitLine:{
                        show: true,
                        lineStyle:{
                            color: 'rgba(255,255,255,0.2)',
                            type:'dashed'
                        }
                    },
                    type: "value",
                    interval: 100,
                    data: [0, 100, 200, 300, 400, 500, 600]
                },
                series: [
                    {
                        name: '游艇',
                        type: 'bar',
                        data: data.order_amount,
                        itemStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 1, color: 'rgba(255, 169, 83, 0.60)'
                                }, {
                                    offset: 0, color: '#FFA953'
                                }],
                                global: false // 缺省为 false
                            }
                        },
                        barMaxWidth: 12
                    },
                    {
                        name: '房车',
                        type: 'bar',
                        data: data.order_amounts,
                        itemStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 1, color: 'rgba(255, 102, 115, 0.60)'
                                }, {
                                    offset: 0, color: '#FF6673'
                                }],
                                global: false // 缺省为 false
                            }
                        },
                        barMaxWidth: 12
                    }
                ]
            };
            leftBottomChart.setOption(leftBottomOption);
            window.addEventListener("resize",function(){
                leftBottomChart.resize();
            });
        },
        centerBottomChart(data){
            var centerBottomChart = this.$echarts.init(this.$refs.centerBottomChart);
            let centerBottomOption = {
                color: [
                    '#91CC75',
                ],
                grid: {
                    left: 0,
                    top: 15,
                    bottom: 0,
                    right: 20,
                    containLabel: true
                },
                
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                axisLabel: {
                    color: "rgba(255, 255, 255, .4)",
                    fontSize: 10
                },
                xAxis: {
                    axisTick:{
                        alignWithLabel: true
                    },
                    axisLabel:{
                        interval:'auto',
                    },
                    boundaryGap: false,
                    data: data.rydate
                },
                yAxis: {
                    axisLine:{
                        show: false
                    },
                    axisTick:{
                        show: false,
                    },
                    splitLine:{
                        show: true,
                        lineStyle:{
                            color: 'rgba(255,255,255,0.2)',
                            type:'dashed'
                        }
                    },
                    type: "value",
                    data: [0, 100, 200, 300, 400, 500, 600]
                },
                series: [
                    {
                        name: 'APP下载趋势',
                        type: 'line',
                        lineStyle: {
                            color: '#91CC75'
                        },
                        showSymbol: false,
                        data: data.order_amount,
                        areaStyle: {
                            opacity: 0.8,
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 1, color: 'rgba(145, 204, 117, 0.3)'
                                }, {
                                    offset: 0, color: 'rgba(145, 204, 117, 0.8)'
                                }],
                                global: false // 缺省为 false
                            }
                        }
                    }
                ]
            };
            centerBottomChart.setOption(centerBottomOption);
            window.addEventListener("resize",function(){
                centerBottomChart.resize();
            });
        },
        rightTopChart(data){
            let array = [];
            data.name.forEach((item,index) => {                
                array.push({value:data.num[index],name:item})
            });
            var rightTopChart = this.$echarts.init(this.$refs.rightTopChart);
            let rightTopOption = {
                color: ['#5470C6','#91CC75','#FAC858','#EE6666','#73C0DE','#3BA272','#FC8452','#9A60B4'],
                legend: {
                    type: "scroll",
                    pageIconColor: "#45A2FF",
                    pageTextStyle:{
                        color: '#FFFFFF',
                        fontSize: 12,
                    },
                    left: -5,
                    itemWidth: 22,
                    itemHeight: 12,
                    textStyle:{
                        color: '#FFFFFF',
                        fontSize: 12
                    },
                    data: data.name
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br> {b} : {d}%'
                },
                series: [
                    {
                        name:'商品类型分析',
                        type: 'pie',
                        // radius: [30, 120],
                        radius: ["20%", "85%"],
                        left: 0,
                        top: 40,
                        center: ['50%', '50%'],
                        roseType: 'area',
                        itemStyle: {
                            borderRadius: 8
                        },
                        labelLine: {
                            length: 1,
                            length2: 0
                        },
                        label:{
                            color: '#FFFFFF',
                            fontSize: 12,
                            alignTo: 'labelLine',
                            bleedMargin: 10,
                        },
                        data:array
                    }
                ]
            };
            rightTopChart.setOption(rightTopOption);
            window.addEventListener("resize",function(){
                rightTopChart.resize();
            });
        },
    },
    watch:{
        leftCenterData:{
            handler(newValue){
                console.log(newValue)
                this.leftCenterData = newValue;
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/style/home.scss";
.copyright{
    padding: 10px 0 20px;
    a{
        color: #FFFFFF;
    }
}
</style>
