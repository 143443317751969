import { login, logout, updatePassword, getinfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { Toast } from 'vant'

const user = {
    state: {
        token: getToken(),
        username: '',
        avatar: '',
        company: '',
        userType: 0
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_USERNAME: (state, username) => {
            state.username = username
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_AURH: (state, auth) => {
            state.auth = auth
        },
        SET_USERTYPE: (state, userType) => {
            state.userType = userType
        }
    },

    actions: {
        // 登录
        Login({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                login(userInfo.mobile, userInfo.captcha, localStorage.getItem('openid')).then(response => {
                    console.log(response.status)
                    console.log(response)
                    var data = response.data
                    if (response.status === 0) {
                        setToken(data.token)
                        //localStorage.setItem('userType', data.user_pass_auth)
                        commit('SET_TOKEN', data.token)
                        commit('SET_USERTYPE', data.userType)
                        resolve()
                    } else {
                        if (response.status === 510) {
                            Toast.fail(response.message)

                        }
                        Toast.fail(response.message)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 用户信息
        GetInfo({ commit }) {
            return new Promise((resolve, reject) => {
                getinfo().then(response => {
                    var data = response.data
                    console.log(data)
                    if (response.status === 0) {
                        commit('SET_USERNAME', data.username)
                        commit('SET_USERTYPE', data.userType)
                        commit('SET_AVATAR', data.avatar)
                        resolve(data)
                    } else {
                        Toast.fail(response.message)
                        if (response.status === 511) {
                            removeToken()
                            setTimeout(() => {
                                window.location = '/login'
                            }, 1000)
                            return Promise.reject(response.data)
                        }
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 修改密码
        UpdatePassword({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                updatePassword( userInfo.password, userInfo.new_password, userInfo.reset_new_password).then(response => {
                    console.log(response)
                    if (response.code === 1) {
                        setToken(response.data.token)
                        commit('SET_TOKEN', response.data.token)
                        resolve(response)
                    } else {
                        Toast.fail(response.msg)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },


        // 登出
        LogOut({ commit }) {
            return new Promise((resolve, reject) => {
                logout().then(() => {
                    commit('SET_TOKEN', '')
                    commit('SET_AURH', '')
                    removeToken()
                    localStorage.removeItem('auth')
                    resolve()
                })
                    .catch(reject)
            })
        }
    }
}

export default user