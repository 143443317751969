import axios from 'axios'
import { Toast } from 'vant'
import { getToken, removeToken } from '@/utils/auth'
import { trimParams } from '@/utils/index'
import store from "@/store";

// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL + 'api', // api 的 base_url
    timeout: 50000, // 请求超时时间
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
    }
})

// request拦截器
service.interceptors.request.use(
    config => {
         if (store.getters.token) {
             config.headers['access-token'] = getToken() // 让每个请求携带自定义token
         }

        if (config.headers['Content-Type'] === 'multipart/form-data') {
            //config.data.set('token', token);
            //return config;
        }
        // 判断请求的类型：如果是post请求就把默认参数拼到data里面；如果是get请求就拼到params里面
        if (config.method === 'post') {
            //config.headers['Content-Type'] = 'multipart/form-data'
            config.data = trimParams({
                //token: token,
                ...config.data
            })
        } else if (config.method === 'get') {
            var  data1 = config.params && config.params.params
            config.params = {
                //token: token,
                ...data1
            }
        }
        return config
    },
    error => {
        console.log(error) // debug
        Promise.reject(error)
    }
)

// response 拦截器
service.interceptors.response.use(
    response => {
        if (response.data.status === 501) {
            Toast.fail('登录已过期，请重新登录~')
            removeToken()
            setTimeout(() => {
                window.location = '/login'
            }, 1000)
            return Promise.reject(response.data)
        }
        if (response.data.status === 507) {
            Toast.fail('无权限，请重新登录~')
            removeToken()
            setTimeout(() => {
                window.location = '/login'
            }, 1000)
            return Promise.reject(response.data)
        }


        return response.data;
    },
    error => {
        return Promise.reject(error)
    }
)

export function get(url, params = {}) {
    return service.get(url, { params: trimParams(params) })
}

export function post(url, data = {}) {
    return service.post(url, data)
}

export function patch(url, data = {}) {
    return service.patch(url, data)
}
export function del(url, data = {}) {
    return service.delete(url, data)
}
export function put(url, data = {}) {
    return service.put(url, data)
}
export function upload(url, formData) {
    return service.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

export default service