import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import getters from './getters'
import { player } from './player'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    keyboard: false,
    //cardInfo: ''
  },
  mutations: {
  },
  actions: {
    // 设置是否调用键盘
    setKeyboard({ state }, value) {
      state.keyboard = value
    }
  },
  modules: {
    player,
    user
  },
  getters
})
