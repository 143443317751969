const utils = {

    getSignUrl:function(){
        var signLink = ''
        var ua = navigator.userAgent.toLowerCase();
        if (/iphone|ipad|ipod/.test(ua)) {
            signLink = localStorage.getItem('WxCodeUrl');
            if(!signLink)signLink = location.href
        } else {
            signLink = location.href
        }
        return signLink;
    },

    // 获取当前url后参数
    getHashParams: function () {
        let hashStr = window.location.hash;
        let params = {};
        if (hashStr.split('?')[1]) {
            let arr = hashStr.split('?')[1].split('&');

            for (let i = 0, len = arr.length; i < len; i++) {
                let data = arr[i].split('=');
                if (data.length === 2) {
                    params[data[0]] = data[1];
                }
            }
        }
        return params;
    },

    getHashParam: function (key) {
        let p = utils.getHashParams();
        return p[key];
    },


    // 校验手机号，支持新号段
    phonePattern: function (tel) {
        return /1[3456789]\d{9}/g.test(tel);
    },

    emailPattern: function (email) {
        var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;

        return reg.test(email);
    },
    /**
     * 时间格式处理
     * @param：时间对象
     * @param：分隔符
     * @param: 保留位数 1:年，2:年月，3:年月日，4:年月日时，5:年月日时分，6:年月日时分秒
     */
    formatTime(date, separate, num) {
        let year = date.getFullYear();
        let month = this.formatNumber(date.getMonth() + 1);
        let day = this.formatNumber(date.getDate());
        let hour = this.formatNumber(date.getHours());
        let minute = this.formatNumber(date.getMinutes());
        let second = this.formatNumber(date.getSeconds());
        if (num == 6) {
            return [year, month, day].join(separate) + ' ' + [hour, minute, second].join(':');
        } else if (num == 5) {
            return [year, month, day].join(separate) + ' ' + [hour, minute].join(':');
        } else if (num == 4) {
            return [year, month, day].join(separate) + ' ' + [hour].join(':');
        } else if (num == 3) {
            return [year, month, day].join(separate);
        } else if (num == 2) {
            return [year, month].join(separate);
        } else if (num == 1) {
            return [year].join(separate);
        } else if (num == 7) {
            return [hour, minute, second].join(':');
        }
    },
    // 时间格式处理
    formatNumber(n) {
        n = n.toString();
        return n[1] ? n : '0' + n;
    },

    /**
     * 获取用户id
     */
    getUserId() {
        if (localStorage.getItem('userId')) {
            return localStorage.getItem('userId');
        } else {
            return ''
        }
    },

    /**
     * 设置标题
     */
    setTitle(title) {
        document.title = title;
    },

    /**
     * 判断是否是微信
     */
    isWeixin() {
        var ua = navigator.userAgent.toLowerCase();
        return ua.match(/MicroMessenger/i) == "micromessenger";
    },
    /**
     * 获取token
     */
    getToken() {
        if (sessionStorage.getItem('token')) {
            return (sessionStorage.getItem('token'))
        } else {
            return (sessionStorage.getItem('urlToken'))
        }
    },

    numberCheck(val) {
        var str = val;
        var len1 = str.substr(0, 1);
        var len2 = str.substr(1, 1);
        //如果第一位是0，第二位不是点，就用数字把点替换掉
        if (str.length > 1 && len1 == 0 && len2 != ".") {
            str = str.substr(1, 1);
        }
        //第一位不能是.
        if (len1 == ".") {
            str = "";
        }
        //限制只能输入一个小数点
        if (str.indexOf(".") != -1) {
            var str_ = str.substr(str.indexOf(".") + 1);
            if (str_.indexOf(".") != -1) {
                str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
            }
        }
        //正则替换，保留数字和小数点
        str = (str.match(/^\d*(\.?\d{0,2})/g)[0]) || null
        return str;
    },

    getTime() {

        let date = new Date()

        let y = date.getFullYear()

        let m = date.getMonth() + 1

        let d = date.getDate()

        let h = date.getHours()

        let min = date.getMinutes()

// let s = date.getSeconds()

        if (m >= 1 && m <= 9) {
            m = `0${m}`
        }

        if (d >= 1 && d <= 9) {
            d = `0${d}`
        }

        if (h >= 0 && h <= 9) {
            h = `0${h}`
        }
        if (min >= 0 && min <= 9) {
            min = `0${min}`
        }
        return `${y}-${m}-${d} ${h}:${min}:00`

    }


};

export default utils;
