import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            auth: true,
            keepAlive: false,
            title: '渔具商城平台APP数据中心'
        }
    }

]

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({y: 0}),
    routes
})


export default router
