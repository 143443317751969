<template>
    <div :class="className" :style="{height:height,width:width}" />
</template>
<script>
    
    import Resize from './mixins/resize.js';
    export default {
        name: 'BarChart',
        mixins: [Resize],
        props: {
            className: {
                type: String,
                default: 'chart'
            },
            width: {
                type: String,
                default: '100%'
            },
            height: {
                type: String,
                default: '100%'
            },
            chartData: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                // Echarts实例
                chart: null
            }
        },
        watch: {
            /* 如果图表数据是后台获取的，监听父组件中的数据变化，重新触发Echarts */
            chartData: {
                deep: true,
                handler(val) {
                    this.setOptions(val)
                }
            }
        },
        mounted () {        
            this.$nextTick(() => {
                this.initChart()
            })
        },
        beforeDestroy() {
            if (!this.chart) {
                return
            }
            /* 释放图表实例 */
            this.chart.dispose()
            /* dispose 会释放内部占用的一些资源和事件绑定，但是解除实例的引用我们是做不到的，所以需要重新赋值为null */
            this.chart = null
        },
        methods: {
            initChart() {
                this.chart = this.$echarts.init(this.$el)
                this.setOptions(this.chartData)
            },
            setOptions({ color, dataX, series } = {}) {
                this.chart.setOption({
                    color: [color],
                    grid: {
                        left: 0,
                        top: 25,
                        bottom: 0,
                        right: 0,
                        containLabel: true
                    },
                    tooltip: {},
                    axisLabel: {
                        color: "rgba(255, 255, 255, .4)",
                        fontSize: 10
                    },
                    xAxis: {
                        axisTick:{
                            alignWithLabel: true
                        },
                        axisLabel:{
                            interval:'auto',
                        },
                        data: dataX,
                    },
                    yAxis: {
                        axisLine:{
                            show: false
                        },
                        axisTick:{
                            show: false,
                        },
                        splitLine:{
                            show: true,
                            lineStyle:{
                                color: 'rgba(255,255,255,0.2)',
                                type:'dashed'
                            }
                        },
                        type: "value"
                    },
                    series: series
                })
            }
        }
    }
</script>