// 当调整浏览器窗口大小时，发生 resize 事件；监听resize，实现Echarts图表大小自适应浏览器窗口大小
export default {
    data() {
        return {
            chart: null
        }
    },
    mounted() {
        window.addEventListener('resize', this.resize);
    },
    methods: {
        resize() {
            if (this.chart) {
                this.chart.resize();
            }
        },
    },
    beforeDestory() {
        window.removeEventListener('resize', this.resize);
    }
}