<template>
    <div id="app">
        <div class="container-bg"></div>
        <div class="title-bg">
            <div class="title">渔具商城平台APP数据中心</div>
            <div class="title-right">
                <span class="title-date">{{date}}</span>
                <span class="title-week">{{week}}</span>
                <span class="title-time">{{time}}</span>
            </div>
            <i class="light"></i>
        </div>
        <router-view v-wechat-title="$route.meta.title"/>
    </div>
</template>

<script>
import utils from './utils/utils';
export default {    
    data() {
        return {
            date:'',
            week:'',
            time:'',
        }
    },
    mounted() {
        this.date = utils.formatTime(new Date(), '-', 3);
        setInterval(() => {
            this.time = utils.formatTime(new Date(), '-', 7);
        }, 1000);

        let days = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
        let today = new Date();
        let weekday = today.getDay();
        this.week = days[weekday];
    },
}
</script>

<style lang="scss" scoped>
#app {
    position: relative;
    min-height: 100vh;
    min-width: 1200px;
    background-color: #000B40;
    overflow: hidden;
    z-index: 1;
}
.container-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background: url(~@/assets/bg/bg.webp) center center #000B40 no-repeat;
    background-size: cover;
    z-index: -1;
}
.title-bg{
    position: relative;
    height: 1rem;
    background: url(~@/assets/bg/title-bg.webp) center center no-repeat;
    background-size: 100% 100%;
}
.title{
    width: 5.88rem;
    height: .62rem;
    font-size: .32rem;
    font-family: AppleSystemUIFont;
    color: #FFFFFF;
    line-height: .62rem;
    text-shadow: 0px .04rem .21rem rgba(27,126,242,0.64);
    text-align: center;
    margin: 0 auto;
}
.title-right{
    position: absolute;
    right: .1rem;
    top: .13rem;
    font-size: .16rem;
    line-height: .32rem;
    span{
        padding: 0 .1rem;
    }
    .title-date{
        color: #45A2FF;
    }
    .title-week{
        color: #45A2FF;
    }
    .title-time{
        color: rgba($color: #FFFFFF, $alpha: 0.87);
        text-shadow: 0px 0px 13px rgba(27,126,242,0.91);
    }
}
.light{
    display: block;
    position: absolute;
    left: 50%;
    width: 1rem;
    margin-left: -.5rem;
    height: .04rem;
    bottom: .24rem;
    &::before{
        display: block;
        content: "";
        position: absolute;
        left: 50%;
        width: 0%;
        height: 100%;
        top: 0;
        // background-color: rgba($color: #FFFFFF, $alpha: 0.87);
        box-shadow: 0 0 .1rem rgba($color: #FFFFFF, $alpha: 1);
        border-radius: 50%;
        animation: lightAni 1.5s linear infinite;
    }
}
@keyframes lightAni{
    0%{
        width: 0;
        left: 50%;
        opacity: 1;
    }
    100%{
        width: 100%;
        left: 0;
        opacity: 0.5;
    }
}
</style>
